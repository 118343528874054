<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt="" @click="goBack" />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">业务人员</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">业务人员详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span> <span>业务人员基本信息</span>
          </div>
        </div>
        <div class="font-size-14 item">
          <el-row>
            <el-col :span="12" class="text-align"
              >姓名:
              <span v-if="agentInfo.name != ''">{{ agentInfo.name }}</span>
              <span v-else>{{ agentInfo.code }}</span>
            </el-col>
            <el-col :span="12" class="text-align"
              >电话号码:{{ agentInfo.phone }}</el-col
            >
            <!-- <el-col :span="12" class="text-align"
        >身份证号:{{ agentInfo.idCardNumber }}</el-col
      >
      <el-col :span="12" class="text-align">
        <span>身份证正面：</span>
        <el-popover
          placement="right-start"
          title="图片"
          trigger="hover"
          v-if="agentInfo.idCardFront"
        >
          <img
            :src="agentInfo.idCardFront"
            alt=""
            srcset=""
            style="width: 120px; height: 80px"
          />
          <span
            slot="reference"
            trigger="hover"
            class="el-icon-postcard font-color-brand"
            style="cursor: pointer"
            >身份证正面</span
          >
        </el-popover>
        <span v-else>暂无</span>
      </el-col>
      <el-col :span="12" class="text-align">
        <span>身份证反面：</span>
        <el-popover
          placement="right-start"
          title="图片"
          trigger="hover"
          v-if="agentInfo.idCardBack"
        >
          <img
            :src="agentInfo.idCardBack"
            alt=""
            srcset=""
            style="width: 120px; height: 80px"
          />
          <span
            slot="reference"
            trigger="hover"
            class="el-icon-postcard font-color-brand"
            style="cursor: pointer"
            >身份证反面</span
          >
        </el-popover>
        <span v-else>暂无</span>
      </el-col> -->
            <!-- <el-col :span="12" class="text-align">
        <span>认证状态：</span>
        <span>
          <el-tag type="success" v-if="agentInfo.isAuthenticated"
            >认证成功</el-tag
          >
          <el-tag type="info" v-if="!agentInfo.isAuthenticated">未认证</el-tag>
        </span></el-col
      > -->
            <!-- <el-col :span="12" class="text-align"
        ><span>当月销售额：</span> <span>18000元</span></el-col
      > -->
          </el-row>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="left_top" style="margin-bottom: 20px">
          <div class="left_icon">
            <span></span> <span>业务人员管理的企业</span>
          </div>
        </div>
        <el-table :data="enterpriseList" border>
          <el-table-column prop="name" label="企业名称" align="center">
          </el-table-column>
          <el-table-column
            prop="idCardNumber"
            label="统一信用代码"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column prop="htyxq" label="合同有效期" align="center">
      </el-table-column>
      <el-table-column prop="qszt" label="签署状态" align="center">
        <template scope="scope">
          <el-tag v-if="scope.row.qszt === '跟进中'" type="info">{{
            scope.row.qszt
          }}</el-tag>
          <el-tag v-if="scope.row.qszt === '已签署'" type="primary">{{
            scope.row.qszt
          }}</el-tag> -->
          <!-- <el-tag v-if="scope.row.qszt === '3'" type="success">{{
            scope.row.qszt
          }}</el-tag> -->
          <!-- <el-tag v-if="scope.row.qszt === '签署失败'" type="danger">{{
            scope.row.qszt
          }}</el-tag>
        </template>
      </el-table-column> -->
          <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope" align="center">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >停止服务人员权限</el-button
          >
        </template>
      </el-table-column> -->
        </el-table>
      </el-card>
    </el-card>
    <!-- <div class="ryXq_btn">
      <el-button @click="handleClick()"> 停止服务人员权限</el-button>
    </div> -->
    <!-- <el-dialog
      title="停止服务人员权限"
      width="40%"
      :visible.sync="outerVisible"
    >
      <el-dialog
        width="50%"
        title="维护人员信息"
        :visible.sync="innerVisible"
        append-to-body
      >
        <div style="padding: 20px">
          <el-input placeholder="请输入内容" v-model="ywyname">
            <template slot="prepend">账号:</template>
          </el-input>
        </div>
        <div style="padding: 20px">
          <el-input placeholder="请输入内容" v-model="password">
            <template slot="prepend">密码:</template>
          </el-input>
        </div>

        <div class="btn-border" style="justify-content: center">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="replacement()">确认</el-button>
        </div>
      </el-dialog>
      <div class="dialog-center">
        <div><span>维护人员：</span> <span>马小波</span></div>
        <div><span>证件编号：</span><span>101220230034004</span></div>
      </div>
      <div class="dialog-center">
        <span>停止服务人员权限后，名下所有服务将由</span>
        <el-select
          v-model="personnel"
          placeholder="请选择"
          size="mini"
          @change="rySelect(personnel)"
        >
          <el-option
            v-for="item in personnelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>维护</span>
      </div>

      <div slot="footer" class="dialog-footer">
     
        <div class="btn-border">
          <el-button type="primary" @click="innerVisible = true"
            >下一步</el-button
          >
          <el-button @click="outerVisible = false">取 消</el-button>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const userDescribe = api()('user.describe.json');
export const agentEnterpriseList = api()('agent.enterprise.list.json');
export default {
  data() {
    return {
      agentInfo: [],
      code: '',
      outerVisible: false,
      innerVisible: false,
      personnel: '',
      enterpriseList: [],
      personnelList: []
    };
  },
  components: {
    // Divider: () => import("../../components/divider")
  },
  async mounted() {
    this.code = this.$route.query.code;
    await this.loadData();
    this.enterpriseData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async loadData() {
      const res = await userDescribe({ code: this.code });
      this.agentInfo = res;
    },
    async enterpriseData() {
      const params = {
        pageSize: 1000,
        pageNumber: 1,
        regexp_agentPath: this.agentInfo.agentPath
      };
      await agentEnterpriseList(params).then(res => {
        this.enterpriseList = res.list;
        this.total = res.total;
      });
    },

    handleClick() {
      this.outerVisible = true;
    },
    rySelect(value) {
      console.log(value);
    },
    replacement() {
      this.innerVisible = false;
      this.outerVisible = false;
    },
    cancel() {
      this.innerVisible = false;
      this.outerVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  padding: 20px;
}
.jcxx-border {
  padding: 20px;
  p {
    padding: 5px 0;
    background: rgba(242, 242, 242, 1);
  }
}
.jcxx-center {
  display: flex;
  align-items: center;

  padding: 20px;
  .center-one {
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .center-one:first-child {
    padding: 0;
  }
}
.ryXq_btn {
  float: right;
  padding: 20px;
}
.dialog-top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-center {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-border {
  display: flex;
  justify-content: flex-end;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
</style>
